import React, { useState } from "react";
import ThemeProvider from "@onnit-js/ui/components/themes/ThemeProvider";
import GlobalStyle from "@onnit-js/ui/components/themes/GlobalStyle";
import theme from "@onnit-js/ui/components/themes/light";
import Box from "@onnit-js/ui/components/box/Box";
import { List, ListItem, Text } from "@onnit-js/ui/components/text";
import useOnnitContext from "@onnit-js/ui/hooks/useOnnitContext";
import { Img, Picture } from "@onnit-js/ui/components/image";
import ProductImageGrid from "@onnit-js/ui/components/module/ProductImageGrid";
import { TwelveColumn } from "@onnit-js/ui/components/module/grid/12/Grid";
import ImageContentPortrait from "@onnit-js/ui/components/module/ImageContentPortrait";
import Embed from "@onnit-js/ui/components/video/Embed";
import Sup from "@onnit-js/ui/components/text/Sup";
import CopyDisplay02 from "@onnit-js/ui/components/module/CopyDisplay02";
import { Testimonial } from "@onnit-js/ui/components/module/testimonials";
import IngredientSliders from "./IngredientSliders";
import UsageAndDisclaimers from "../../components/common/UsageAndDisclaimers";
import DefaultProductPageTop from "../../components/common/layouts/DefaultProductPageTop";
import SupplementFacts from "../../components/common/SupplementFacts";
import usePageData from "../../hooks/usePageData";

export default function Page() {
    const onnitContext = useOnnitContext();
    const { pageData } = usePageData();
    // Used to set current products supplement facts image.
    const [currentPID, setCurrentPID] = useState<number | undefined>();

    if (!pageData || !onnitContext) {
        return null;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const firstProducts = [pageData.products[1], pageData.other_products[0]].map((product) => product);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const secondProducts = [pageData.products[0], pageData.other_products[1]].map((product) => product);

    const orange = "#F5A800";

    const IMG_URL = `${onnitContext.url.cdn.static_images}/product-page/alpha-brain/refresh-full`;

    return (
        <ThemeProvider theme={theme}>
            <>
                <GlobalStyle />

                <DefaultProductPageTop
                    onProductChange={(product) => {
                        setCurrentPID(product.id);
                    }}
                >
                    <ProductImageGrid>
                        <Picture
                            lazyLoad={false}
                            width={944}
                            height={424}
                            src={[
                                `${IMG_URL}/2023-AB-Wpage-Gallery-1-920x920.png?q=90&fm=pjpg&auto=compress,format`,
                                `${IMG_URL}/2023-AB-Wpage-Gallery-1-1940x873.png?fm=pjpg&auto=compress,format`,
                            ]}
                            alt="Single bottle of Alpha BRAIN"
                        />
                        <Picture
                            lazyLoad={false}
                            width={460}
                            height={460}
                            src={[`${IMG_URL}/2023-AB-Wpage-Gallery-2-920x920.png?fm=pjpg&auto=compress,format`]}
                            alt="Gamer with a dose of Alpha BRAIN"
                        />

                        <Picture
                            lazyLoad={false}
                            width={460}
                            height={460}
                            src={[`${IMG_URL}/2023-AB-Wpage-Gallery-3-920x920.png?q=90&fm=pjpg&auto=compress,format`]}
                            alt="BMX Athlete takes Alpha BRAIN"
                        />

                        <Picture
                            lazyLoad={false}
                            width={944}
                            height={424}
                            src={[
                                `${IMG_URL}/2023-AB-Wpage-Gallery-4-920x920.png?q=90&fm=pjpg&auto=compress,format`,
                                `${IMG_URL}/2023-AB-Wpage-Gallery-4-1940x873.png?fm=pjpg&auto=compress,format`,
                            ]}
                            alt="Alpha BRAIN® capsules on cutting board next to ingredients"
                        />
                    </ProductImageGrid>
                </DefaultProductPageTop>

                <div className="hide-ie">
                    <Box backgroundColor="black" py={[6, 6, 6, 8]}>
                        <TwelveColumn display={["none", "none", "grid"]} mb={4}>
                            <Box gridColumn="2 / span 7" px={4}>
                                <Text as="h2" color="grays.3" typeStyle="text02">
                                    Alpha BRAIN
                                    <Sup variant="reg" />
                                </Text>
                            </Box>
                        </TwelveColumn>
                        <TwelveColumn display={["block", "block", "grid"]} mb={[0, 0, 6]}>
                            <Box gridColumn="7 / span 5" px={4}>
                                <Embed
                                    title="Alpha BRAIN® product video"
                                    src="https://player.vimeo.com/video/859816921"
                                />
                            </Box>
                            <Box
                                gridColumn="2 / span 5"
                                display={["block", "block", "flex"]}
                                alignItems="center"
                                px={4}
                            >
                                <Text
                                    display={["block", "block", "none"]}
                                    as="h2"
                                    color="grays.3"
                                    typeStyle="text02"
                                    textTransform="uppercase"
                                    my={3}
                                >
                                    Alpha BRAIN
                                    <Sup variant="reg" />
                                </Text>

                                <Text as="h2" color={orange} typeStyle="title01" mb={2}>
                                    The ultimate way to get &ldquo;in the zone&rdquo;
                                    <Sup variant="dagger" fontSize={2} />
                                </Text>
                            </Box>
                        </TwelveColumn>

                        <TwelveColumn display={["block", "block", "grid"]}>
                            <Box gridColumn="2 / span 6" px={4} mb={[6, 6, 6, 8]}>
                                <Text as="p" typeStyle="text02" color="onGray">
                                    <span style={{ fontWeight: "black" }}>Alpha BRAIN</span>
                                    <Sup variant="reg" /> is a caffeine-free nootropic blend that helps optimize
                                    cognitive function.
                                    <Sup variant="dagger" /> More than 10 million bottles have been sold.
                                </Text>
                            </Box>
                        </TwelveColumn>

                        <TwelveColumn py={[6, 6, 6, 8]} display={["block", "block", "grid"]} backgroundColor="black">
                            <Box gridColumn="3 / span 7" px={4}>
                                <Text as="h2" color={orange} typeStyle="title01" mb={2}>
                                    Alpha BRAIN
                                    <Sup variant="reg" /> Is For:
                                </Text>
                                <List>
                                    <ListItem typeStyle="text02" color="onGray">
                                        Students studying
                                    </ListItem>
                                    <ListItem typeStyle="text02" color="onGray">
                                        Professionals at work
                                    </ListItem>
                                    <ListItem typeStyle="text02" color="onGray">
                                        Gamers, artists, entrepreneurs
                                    </ListItem>
                                </List>
                            </Box>
                        </TwelveColumn>
                    </Box>

                    <CopyDisplay02 py={[4, 4, 4, 6]} dashColor={orange}>
                        <Text as="h2" typeStyle="copyDisplay02" textTransform="none" mb={[6, 6, 6, 8]}>
                            GO WITH THE FLOW
                        </Text>

                        <Text as="p" typeStyle="copyHero02" mb={6} color="grays.6" maxWidth="840px">
                            You know those times when you’re so into what you’re doing that you can’t think about
                            anything else? The days when you read half a dozen chapters, write a thousand words, or
                            finish a work assignment without looking up once (and when you do, you notice it’s suddenly
                            dark outside).
                        </Text>
                        <Text as="p" typeStyle="copyHero02" mb={6} color="grays.6" maxWidth="840px">
                            How’d you like to feel like that more often?
                        </Text>
                        <Text as="p" typeStyle="copyHero02" mb={6} color="grays.6" maxWidth="840px">
                            You can.
                        </Text>
                        <Text as="p" typeStyle="copyHero02" mb={6} color="grays.6" maxWidth="840px">
                            The feeling of being in the zone—what some in the psychology world call &ldquo;flow
                            state&rdquo;—is a result of the brain producing more alpha waves, electrical pulses that are
                            associated with creativity and productivity. As a result, you’re able to tune out
                            distractions, think clearly, and accomplish tasks.
                            <Sup variant="dagger" /> <Sup>1-3</Sup>
                        </Text>
                        <Box mb={6} color="grays.6" maxWidth="840px">
                            <Testimonial
                                imgSrc={`${IMG_URL}/2023-AB-Wpage-image-testimonial.png`}
                                quote={`"It seems to fire up your brain at a higher RPM level..."`}
                                author="JOE ROGAN"
                            />
                        </Box>
                    </CopyDisplay02>

                    <IngredientSliders
                        imageUrl={`${onnitContext.url.cdn.static_images}/product-page/alpha-brain/refresh-full`}
                    />

                    <Box py={[6, 6, 6, 8]}>
                        <ImageContentPortrait
                            py={[6, 6, 6, 8]}
                            reverse
                            image={(
                                <Img
                                    alt="Gamer in his room"
                                    placeholder={[464, 600]}
                                    src={`${IMG_URL}/2023-AB-Wpage-How.png?q=40`}
                                    width={926}
                                    height={1200}
                                />
                            )}
                            content={(
                                <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
                                    <Text as="h2" typeStyle="copyDisplay02" mb={[4, 4, 6]}>
                                        How Alpha BRAIN
                                        <Sup variant="reg" /> Works
                                    </Text>
                                    <Text as="p" typeStyle="text02" mb={[4, 4, 5]}>
                                        If you want to help your brain think more clearly, you need to concentrate on
                                        two things: promoting the production of alpha waves in the brain and supporting
                                        acetylcholine levels—a key neurotransmitter.
                                        <Sup variant="dagger" /> We formulated Alpha BRAIN
                                        <Sup variant="reg" /> with both goals in mind.
                                    </Text>
                                </Box>
                            )}
                        />
                    </Box>
                </div>

                {pageData.suggested_use && pageData.supplement_facts && (
                    <UsageAndDisclaimers
                        /* eslint-disable-next-line react/jsx-no-bind */
                        supplementFacts={<SupplementFacts currentPID={currentPID} />}
                    >
                        <Box>
                            <Text as="p" fontSize={0} color="grays.4" fontWeight="heavy" display="inline" mr={1}>
                                WARNING:
                            </Text>
                            <Text as="p" fontSize={0} display="inline" typeStyle="default" color="grays.4" mb={4}>
                                Consult a medical doctor before taking this or any other nutritional supplement if you
                                have or suspect a medical condition and/or are taking any medications. Not to be used in
                                pregnant or breastfeeding women or in adolescents under 18 years of age. Do not use if
                                you are pregnant or nursing. Store in a cool, dry place.{" "}
                                <strong>KEEP OUT OF REACH OF CHILDREN.</strong>
                            </Text>
                        </Box>
                    </UsageAndDisclaimers>
                )}

                <Box py={[6, 6, 6, 8]} display="flex" justifyContent="center" mx={4}>
                    <Box display="flex" flexDirection="column" maxWidth="736px">
                        <Text as="p" typeStyle="text04" mb={4} color="grays.6">
                            REFERENCES
                        </Text>
                        <Text as="p" mb={4} color="grays.6">
                            1. Csikszentmihalyi, Mihaly. "Mihaly Csikszentmihalyi." Mihály Csíkszentmihályi (2004): 1.
                        </Text>
                        <Text as="p" mb={4} color="grays.6">
                            2. Csikszentmihalyi, M. (1988). The flow experience and its significance for human
                            psychology. In M. Csikszentmihalyi & I. Csikszentmihalyi (Eds.), Optimal Experience:
                            Psychological Studies of Flow in Consciousness. Cambridge: Cambridge University Press.
                            doi:10.1017/CBO9780511621956.002
                        </Text>
                        <Text as="p" mb={4} color="grays.6">
                            3. Csikszentmihalyi, Mihaly (1990). Flow: The Psychology of Optimal Experience. Harper
                            Perennial Modern Classics.
                        </Text>
                    </Box>
                </Box>
            </>
        </ThemeProvider>
    );
}
